import { format, parse } from 'date-fns'
import { ru } from 'date-fns/locale'

export const formatDate = (date) => {
  if (date !== null) return format(new Date(date), 'd MMM yyyy', { locale: ru })
}

export const formatDateTime = (date) => {
  if (date !== null)
    return format(new Date(date), 'dd MMM yyyy H:mm', { locale: ru })
}

export const formatDateByParseString = (date) => {
  const parsedDate = parse(date, 'd.M.yyyy', new Date())
  return format(parsedDate, 'd MMM yyyy', { locale: ru })
}

export const formatDateByTemplate = (date, template = 'd MMM yyyy HH:mm') => {
  if (date) {
    const currentDate = typeof date === Date ? date : new Date(date)
    return format(currentDate, template, { locale: ru })
  }
}
